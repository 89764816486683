import React, {useMemo, useState} from 'react';
import {Autocomplete, Container, FormControl, InputLabel, MenuItem, Select, Skeleton, TextField, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import * as moment from 'moment';
import {toCurrency} from '@src/modules/accounts/utils';
import {useListAllQuery, useListGeneralBoletosQuery} from '@src/modules/accounts/accounts.api';
import Table from '@src/components/Table';
import {boletosStatus, translateBoletosStatus} from '@src/modules/accounts/schema';
import {DatePicker} from '@mui/x-date-pickers';

import styles from './boletos.module.scss';
import {TableActions} from './components/TableActions';
import {BoletoStatus} from './components/BoletoStatus';

const pageSize = 10;

export default function Boletos(){    
    const {t} = useTranslation();
    const allDataOption = {
        label: t('BOLETOS.ALL'),
        value: 'all',
    };

    const [account, setAccount] = useState(allDataOption);
    const [status, setStatus] = useState('all');
    const [initialDate, setInitialDate] = useState(undefined);
    const [finalDate, setFinalDate] = useState(undefined);
    const [page, setPage] = useState(0);

    const {data, isLoading: boletosLoading, isError, refetch: updateList} = useListGeneralBoletosQuery({    
        params: {
            page,
            pageSize,
            account: account?.value === 'all' ? undefined : account?.value,
            status: status === 'all' ? undefined : status,
            from: initialDate ? moment(initialDate).startOf('day').toISOString() : undefined,
            to: finalDate ? moment(finalDate).endOf('day').toISOString() : undefined,
        },
    }, {
        refetchOnMountOrArgChange: true,
    });
    const {data: accountsData, isLoading: accountsLoading} = useListAllQuery({type: 'commercials'});

    const columns = useMemo(() => [
        {accessor: 'company', Header: t('BOLETOS.COMPANY')},
        {accessor: 'value', Header: t('BOLETOS.VALUE')},
        {accessor: 'createdAt', Header: t('BOLETOS.CREATED_AT')},
        {accessor: 'expirationDate', Header: t('BOLETOS.EXPIRATION_DATE')},
        {accessor: 'payDay', Header: t('BOLETOS.PAY_DAY')},
        {accessor: 'status', Header: t('BOLETOS.STATUS'), Cell: ({cell: {value}}) => <BoletoStatus status={value} />},
    ], [t]);
    
    const rows = useMemo(() => {
        if (!data) {
            return [];
        }
        
        return [...data.items]
            .sort((a, b) => moment(b.created_at) - moment(a.created_at))
            .map((row) => ({
                id: row.id,
                account: row.account,
                company: row.account_name,
                value: toCurrency(row.value),
                status: row.status,
                expirationDate: moment(row?.boleto_expiration_date).format('DD/MM/YYYY'),
                createdAt: moment(row.created_at).format('DD/MM/YYYY HH:mm:ss'),
                url: row.boleto_url,
                payDay: row?.capturedDate ? moment(row?.capturedDate).format('DD/MM/YYYY HH:mm:ss') : '',
            }));
    }, [data]);
        
    const accountsOptions = useMemo(
        () =>  accountsData
            ?.filter(item => item.name)
            ?.sort((a, b) => a.name.localeCompare(b.name))
            ?.map(account => {
                return {
                    label: `${account?.name}${account?.cnpj ? ` - ${account.cnpj}` : ''}`,
                    value: String(account?.id),
                };
            }),
        [accountsData],
    );
        
    const statusOptions = useMemo(() => Object.keys(boletosStatus).map((item) => ({
        label: t(translateBoletosStatus[boletosStatus[item]]),
        value: boletosStatus[item],
    })), []);
        
    return (
        <Container maxWidth='xl'>
            <header>
                <Typography variant='h4' component='h2'>
                    {t('BOLETOS.NAME')}
                </Typography>
            </header>
            <main className={styles.main}>
                <div className={styles.filters}>
                    <div className={styles.accountFilter}>
                        {accountsLoading ? (
                            <Skeleton variant='rounded' width='100%' height={56} />
                        ) : (
                            <Autocomplete
                                labelId='account-filter'
                                disablePortal
                                disableClearable
                                options={[allDataOption, ...accountsOptions]}
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                value={account}
                                onChange={(_event, newValue) => {
                                    setAccount(newValue);
                                    setPage(0);
                                }}
                                className={styles.accountsSelect}
                                noOptionsText={t('BOLETOS.NO_OPTIONS')}
                                renderInput={(params) => <TextField {...params} label={t('BOLETOS.ACCOUNT')} />}
                                data-qa='boleto-account'
                            />
                        )}
                    </div>
                    <div className={styles.statusFilter}>
                        <FormControl fullWidth>
                            <InputLabel id='status-filter'>{t('BOLETOS.STATUS')}</InputLabel>
                            <Select
                                labelId='status-filter'
                                value={status}
                                label={t('BOLETOS.STATUS')}
                                onChange={(e) => {
                                    setStatus(e.target.value);
                                    setPage(0);
                                }}
                                data-qa='boleto-status'
                            >
                                {[allDataOption, ...statusOptions].map((item) => (
                                    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={styles.dateFilter}>
                        <DatePicker
                            name='initial-date'
                            data-qa='boleto-initial-date'
                            value={initialDate}
                            onChange={(date) => {
                                setInitialDate(date);
                                setPage(0);
                            }}
                            localeText='pt-br'
                            sx={{width: '100%'}}
                            label={t('BOLETOS.INITIAL_DATE')}
                            maxDate={moment(finalDate) || undefined}
                        />
                    </div>
                    <div className={styles.dateFilter}>
                        <DatePicker
                            name='final-date'
                            data-qa='boleto-final-date'
                            value={finalDate}
                            onChange={(date) => {
                                setFinalDate(date);
                                setPage(0);
                            }}
                            sx={{width: '100%'}}
                            localeText='pt-br'
                            label={t('BOLETOS.FINAL_DATE')}
                            minDate={moment(initialDate) || undefined}
                        />
                    </div>
                </div>
                <Table
                    columns={columns}
                    isError={isError}
                    data={rows}
                    notFoundText={t('BOLETOS.NO_BOLETOS_FOUND')}
                    Actions={(props) => <TableActions {...props} updateList={updateList} />}
                    actionsClassName={styles.actions}
                    loading={boletosLoading}
                    setPage={setPage}
                    pageSize={pageSize}
                    total={data?.total}
                    pageActual={page}
                />
            </main>
        </Container>
    );
}
