import {routes} from '@src/utils/routes';
import React from 'react';
import Scaffolding from '@src/components/Scaffolding';
import Accounts from '@src/screens/Accounts';
import AccountCreate from '@src/screens/AccountCreate';
import AccountEdit from '@src/screens/AccountEdit';
import AccountDashboard from '@src/screens/AccountDashboard';
import DemoAccounts from '@src/screens/DemoAccounts';
import DemoAccountCreate from '@src/screens/DemoAccountCreate';
import FrequentlyAskedQuestionsList from '@src/screens/FrequentlyAskedQuestions/List';
import FrequentlyAskedQuestionsCreate from '@src/screens/FrequentlyAskedQuestions/Create';
import FrequentlyAskedQuestionsEdit from '@src/screens/FrequentlyAskedQuestions/Edit';
import Suggestions from '@src/screens/Suggestions/List';
import SuggestionsDetails from '@src/screens/Suggestions/Details';
import TutorialsList from '@src/screens/Tutorials/List';
import TutorialsCreate from '@src/screens/Tutorials/Create';
import TutorialsEdit from '@src/screens/Tutorials/Edit';
import TrainingsList from '@src/screens/Trainings/List';
import TrainingsCreate from '@src/screens/Trainings/Create';
import TrainingsEdit from '@src/screens/Trainings/Edit';
import TrainingsTopics from '@src/screens/Trainings/Topics';
import Admins from '@src/screens/Admins/List';
import CreateAdmins from '@src/screens/Admins/Create';
import EditAdmins from '@src/screens/Admins/Edit';
import ImportUsers from '@src/screens/ImportUsers';
import GeneralDashboard from '@src/screens/GeneralDashboard';
import Boletos from '@src/screens/Boletos';

import {useCan} from './useCan';

export const permissionTypes = {
    manage: 'manage',
    list: 'list',
    detail: 'detail',
    create: 'create',
    delete: 'delete',
    edit: 'edit',
};

export const permissionResources = {
    commercialAccounts: 'commercialAccounts',
    trainingAccounts: 'trainingAccounts',
    faq: 'faq',
    notes: 'notes',
    tutorials: 'tutorials',
    trainings: 'trainings',
    admins: 'admins',
    boletos: 'boletos',
    generalDashboard: 'generalDashboard',
    history: 'history',
};

export const usePrivateRoutes = () => {
    const can = useCan();

    const privateRoutes = [
        {
            path: routes.ROOT,
            element: <Scaffolding />,
            allowed: true,
        },
        {
            path: routes.ACCOUNTS,
            element: <Accounts />,
            allowed: can(permissionResources.commercialAccounts, permissionTypes.list),
        },
        {
            path: routes.ACCOUNT_CREATE,
            element: <AccountCreate />,
            allowed: can(permissionResources.commercialAccounts, permissionTypes.create),
        },
        {
            path: routes.ACCOUNT_EDIT(),
            element: <AccountEdit />,
            allowed: can(permissionResources.commercialAccounts, permissionTypes.edit) || can(permissionResources.commercialAccounts, permissionTypes.detail)
            || can(permissionResources.trainingAccounts, permissionTypes.edit) || can(permissionResources.trainingAccounts, permissionTypes.detail),
        },
        {
            path: routes.ACCOUNT_DASHBOARD(),
            element: <AccountDashboard />,
            allowed: can(permissionResources.commercialAccounts, permissionTypes.detail),
        },
        {
            path: routes.DEMO_ACCOUNTS,
            element: <DemoAccounts />,
            allowed: can(permissionResources.trainingAccounts, permissionTypes.list),
        },
        {
            path: routes.DEMO_ACCOUNT_CREATE,
            element: <DemoAccountCreate />,
            allowed: can(permissionResources.trainingAccounts, permissionTypes.create),
        },
        {
            path: routes.FAQ,
            element: <FrequentlyAskedQuestionsList />,
            allowed: can(permissionResources.faq, permissionTypes.manage),
        },
        {
            path: routes.FAQ_CREATE,
            element: <FrequentlyAskedQuestionsCreate />,
            allowed: can(permissionResources.faq, permissionTypes.manage),
        },
        {
            path: routes.FAQ_EDIT(),
            element: <FrequentlyAskedQuestionsEdit />,
            allowed: can(permissionResources.faq, permissionTypes.manage),
        },
        {
            path: routes.SUGGESTIONS,
            element: <Suggestions />,
            allowed: can(permissionResources.notes, permissionTypes.manage),
        },
        {
            path: routes.SUGGESTIONS_DETAILS(),
            element: <SuggestionsDetails />,
            allowed: can(permissionResources.notes, permissionTypes.manage),
        },
        {
            path: routes.TUTORIALS,
            element: <TutorialsList />,
            allowed: can(permissionResources.tutorials, permissionTypes.manage),
        },
        {
            path: routes.TUTORIALS_CREATE,
            element: <TutorialsCreate />,
            allowed: can(permissionResources.tutorials, permissionTypes.manage),
        },
        {
            path: routes.TUTORIALS_EDIT(),
            element: <TutorialsEdit />,
            allowed: can(permissionResources.tutorials, permissionTypes.manage),
        },
        {
            path: routes.TRAININGS,
            element: <TrainingsList />,
            allowed: can(permissionResources.trainings, permissionTypes.list),
        },
        {
            path: routes.TRAININGS_CREATE,
            element: <TrainingsCreate />,
            allowed: can(permissionResources.trainings, permissionTypes.create),
        },
        {
            path: routes.TRAININGS_EDIT(),
            element: <TrainingsEdit />,
            allowed: can(permissionResources.trainings, permissionTypes.edit) || can(permissionResources.trainings, permissionTypes.detail),
        },
        {
            path: routes.TRAININGS_TOPICS(),
            element: <TrainingsTopics />,
            allowed: can(permissionResources.trainings, permissionTypes.edit) || can(permissionResources.trainings, permissionTypes.detail),
        },
        {
            path: routes.ADMINS,
            element: <Admins />,
            allowed: can(permissionResources.admins, permissionTypes.manage),
        },
        {
            path: routes.ADMINS_CREATE,
            element: <CreateAdmins />,
            allowed: can(permissionResources.admins, permissionTypes.manage),
        },
        {
            path: routes.ADMINS_EDIT(),
            element: <EditAdmins />,
            allowed: can(permissionResources.admins, permissionTypes.manage),
        },
        {
            path: routes.IMPORT_USERS(),
            element: <ImportUsers />,
            allowed: can(permissionResources.trainingAccounts, permissionTypes.edit),
        },
        {
            path: routes.GENERAL_DASHBOARD,
            element: <GeneralDashboard />,
            allowed: can(permissionResources.generalDashboard, permissionTypes.manage),
        },
        {
            path: routes.BOLETOS,
            element: <Boletos />,
            allowed: can(permissionResources.boletos, permissionTypes.manage),
        },
    ].filter(i => i.allowed);

    return privateRoutes;
};
