import React from 'react';
import {boletosStatus, translateBoletosStatus} from '@src/modules/accounts/schema';
import {useTranslation} from 'react-i18next';
import {Chip} from '@mui/material';

import styles from './boleto-status.module.scss';

export function BoletoStatus({status}) {
    const {t} = useTranslation();
    const statusStyle = {
        [boletosStatus.PENDING]: styles.chipPending,
        [boletosStatus.EXPIRED]: styles.chipExpired,
        [boletosStatus.PAID]: styles.chipPaid,
    };

    return (
        <Chip
            label={t(translateBoletosStatus[status])}
            color={'default'}
            className={statusStyle[status]}
            size='small'
            variant='outlined'  
        />
    );
}
