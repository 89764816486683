import React from 'react';
import {useTranslation} from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import styles from './boletoInfoCard.module.scss';

export function BoletoInfoCard() {
    const {t} = useTranslation(); 
    return (
        <div className={styles.container}>
            <InfoOutlinedIcon />
            <p className={styles.text}>
                {t('ACCOUNTS_PAGE.BOLETO_DISCLAIMER')}
            </p>
        </div>
    );
}
