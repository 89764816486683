import {useCan} from '@src/hooks/useCan';
import {permissionResources, permissionTypes} from '@src/hooks/usePrivateRoutes';
import FinancialData from '@src/modules/accounts/components/FinancialData';
import FinancialResponsible from '@src/modules/accounts/components/FinancialResponsible';
import {BoletoInfoCard} from '@src/screens/AccountEdit/components/BoletoInfoCard';
import React from 'react';

export function FinanceCreateTab({formik, file, setFile, isLoading}) {
    const can = useCan();
    const canManageBoletos = can(permissionResources.boletos, permissionTypes.manage);
    return (
        <>
            {canManageBoletos && <BoletoInfoCard />}
            <FinancialData
                values={formik.values}
                setFieldValue={formik.setFieldValue}
                formik={formik}
                file={file}
                setFile={setFile}
            />
            <FinancialResponsible
                formik={formik}
                readOnly={isLoading}
                disabled={isLoading}
            />
        </>
    );
}
