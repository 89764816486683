import {useCan} from '@src/hooks/useCan';
import {permissionResources, permissionTypes} from '@src/hooks/usePrivateRoutes';
import AccountData from '@src/modules/accounts/components/AccountData';
import {normalizeString} from '@src/modules/accounts/utils';
import {BoletoInfoCard} from '@src/screens/AccountEdit/components/BoletoInfoCard';
import {useEffect, useState} from 'react';
import React from 'react';

export function ClientCreateTab({formik, isLoading}) {
    const [dataCep, setDataCep] = useState();
    const can = useCan();
    const canManageBoletos = can(permissionResources.boletos, permissionTypes.manage);

    useEffect(() => {
        if (dataCep) {
            formik.setFieldValue('address.postal_code', dataCep?.erro ? '' : normalizeString(dataCep?.cep));
            formik.setFieldValue('address.street', dataCep?.erro ? '' : normalizeString(dataCep?.logradouro));
            formik.setFieldValue('address.neighborhood', dataCep?.erro ? '' : normalizeString(dataCep?.bairro));
            formik.setFieldValue('address.city', dataCep?.erro ? '' : normalizeString(dataCep?.localidade));
            formik.setFieldValue('address.state', dataCep?.erro ? '' : normalizeString(dataCep?.uf));
        }
    }, [dataCep]);

    return (
        <>
            {canManageBoletos && <BoletoInfoCard />}
            <AccountData
                formik={formik}
                setFieldValue={formik.setFieldValue}
                setDataCep={setDataCep}
                readOnly={isLoading}
                disabled={isLoading}
        />
        </>
    );
}
