import React from 'react';
import {useDuplicateBoletoMutation} from '@src/modules/accounts/accounts.api';
import * as moment from 'moment';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {Box, IconButton, Tooltip} from '@mui/material';
import {CopyAllOutlined, RemoveRedEye, RestorePage} from '@mui/icons-material';
import {boletosStatus} from '@src/modules/accounts/schema';

import styles from './table-actions.module.scss';

export function TableActions({data, updateList}) {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const [requestSecondBoleto, isLoading] = useDuplicateBoletoMutation();
    const isExpired = moment().startOf('d').isAfter(moment(data.expirationDate, 'DD/MM/YYYY').endOf('d'));
    
    const handleCopy = () => {
        navigator.clipboard.writeText(data.url);
        enqueueSnackbar(t('COPIED'), {variant: 'success'});
    };

    const handle2NDCopy = async () => {
        enqueueSnackbar(t('ACCOUNTS_PAGE.BOLETOS.YOUR_BOLETO_IS_BEING_GENERATED_WAIT'), {variant: 'warning'});
        try {
            await requestSecondBoleto({accountId: data.account, id: data?.id}).unwrap();
            enqueueSnackbar(t('ACCOUNTS_PAGE.BOLETOS.GENERATE_2ND_COPY_SUCCESS'), {variant: 'success'});
            updateList();
        } catch (error){
            enqueueSnackbar(t(error.message || 'ACCOUNTS_PAGE.BOLETOS.ERROR_GENERATING_BOLETO'), {variant: 'error'});
        }
    };
 
    return (
        <div className={styles.actions}>
            <Box ml={2}>
                {isExpired && data.status !== boletosStatus.PAID &&  (
                    <Tooltip title={t('ACCOUNTS_PAGE.BOLETOS.GENERATE_2ND_COPY')}>
                        <IconButton size='small' onClick={handle2NDCopy} disabled={isLoading?.isLoading}>
                            <RestorePage />
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip title={t('COPY')}>
                    <IconButton 
                        disabled={data?.status !== boletosStatus.PENDING}
                        size='small'
                        onClick={handleCopy}
                    >
                        <CopyAllOutlined />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('ACCOUNTS_PAGE.BOLETOS.VIEW')}>
                    <IconButton 
                        disabled={data?.status !== boletosStatus.PENDING}
                        size='small'
                    >
                        <a
                            href={data.url}
                            target='_blank'
                            rel='noreferrer'
                            className={styles.seeBoletoLink}
                        >
                            <RemoveRedEye />
                        </a>
                    </IconButton>
                </Tooltip>
            </Box>
        </div>
    );
}
