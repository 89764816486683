import AccountData from '@src/modules/accounts/components/AccountData';
import {routes} from '@src/utils/routes';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import React from 'react';
import {useCan} from '@src/hooks/useCan';
import {permissionResources, permissionTypes} from '@src/hooks/usePrivateRoutes';
import {Button} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {normalizeString} from '@src/modules/accounts/utils';

import {BoletoInfoCard} from '../../BoletoInfoCard';

import styles from './clientEditTab.module.scss';

export function ClientEditTab({formik, isLoading}) {
    const [dataCep, setDataCep] = useState();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const can = useCan();
    const permissionResource = permissionResources.commercialAccounts;
    const canManageBoletos = can(permissionResources.boletos, permissionTypes.manage);

    useEffect(() => {
        if (dataCep) {
            formik.setFieldValue('address.postal_code', dataCep?.erro ? '' : normalizeString(dataCep?.cep));
            formik.setFieldValue('address.street', dataCep?.erro ? '' : normalizeString(dataCep?.logradouro));
            formik.setFieldValue('address.neighborhood', dataCep?.erro ? '' : normalizeString(dataCep?.bairro));
            formik.setFieldValue('address.city', dataCep?.erro ? '' : normalizeString(dataCep?.localidade));
            formik.setFieldValue('address.state', dataCep?.erro ? '' : normalizeString(dataCep?.uf));
        }
    }, [dataCep]);

    return (
        <form
            onSubmit={formik.handleSubmit}
            autoComplete='off'
        >
            {canManageBoletos && <BoletoInfoCard />}
            <AccountData
                formik={formik}
                setFieldValue={formik.setFieldValue}
                setDataCep={setDataCep}
                readOnly={isLoading}
                disabled={isLoading}
            />
            {can(permissionResource, permissionTypes.edit) && (
                <div className={styles.buttonsContainer}>
                    <Button
                        variant='outlined'
                        disabled={isLoading}
                        data-qa='account-client-cancel'
                        onClick={() => navigate(routes.ACCOUNTS)}
                         >
                        {t('ACCOUNTS_PAGE.CANCEL')}
                    </Button>

                    <LoadingButton
                        type='submit'
                        loading={isLoading}
                        disabled={isLoading || !formik.dirty}
                        data-qa='account-client-loading'
                        variant='contained'
                         >
                        {t('ACCOUNTS_PAGE.SAVE')}
                    </LoadingButton>
                </div>
            )}
        </form>
    );
}
