import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Tab} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {useNavigate, useParams} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {useEditMutation, useGetContractUrlMutation} from '@src/modules/accounts/accounts.api';
import {routes} from '@src/utils/routes';
import {uploadFile} from '@src/utils/file-utils';
import {useFormik} from 'formik';
import {editAccountSchema} from '@src/modules/accounts/schema';
import {useCan} from '@src/hooks/useCan';
import {permissionResources, permissionTypes} from '@src/hooks/usePrivateRoutes';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import {AccountEditTab} from '../EditTabs/AccountEditTab';
import {ClientEditTab} from '../EditTabs/ClientEditTab';
import {FinanceEditTab} from '../EditTabs/FinanceEditTab';
import {ModulesEditTab} from '../EditTabs/ModulesEditTab';
import {BoletosEditTab} from '../EditTabs/BoletosEditTab';
import {AuditTab} from '../EditTabs/AuditTab';
import {AuditUserTab} from '../EditTabs/AuditUserTab';

import styles from './edit-form.module.scss';

export function EditForm({initialValues, logoUrl}) {
    const [isLoading, setIsLoading] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [file, setFile] = useState();
    const {t} = useTranslation();
    const can = useCan();
    const [getUploadContratUrl] = useGetContractUrlMutation();
    const {accountType, id} = useParams();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const [edit] = useEditMutation();
    const canListBoletos = can(permissionResources.boletos, permissionTypes.list);
    const canListHistory = can(permissionResources.history, permissionTypes.list);
    const handleChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    const formik = useFormik({
        validationSchema: editAccountSchema(t),
        initialValues: initialValues,
        enableReinitialize: true,
        onSubmit,
    });
    const {errors, touched} = formik;

    const accountFieldsErrors = ['name', 'subdomain', 'max_users', 'timezone', 'responsible', 're'];
    const clientFieldErrors = ['company_name', 'cnpj', 'address'];
    const financeFieldErros = [
        'value_per_user',
        'min_users_number',
        'contract_initial_day',
        'contract_final_day',
        'close_invoice',
        'payment_day',
        'financial_responsible',
    ];

    const showWarningIcon = useCallback((fields) => {
        if (fields.some(field => field in errors && field in touched)) {
            return <WarningAmberIcon color='error' />;
        }

        return null;
    }, [errors, touched]);
    
    async function onSubmit(form) {
        try {
            setIsLoading(true);
            
            await edit({
                id: `${accountType}/${id}`,
                body: {
                    name: form.name,
                    subdomain: form.subdomain,
                    max_users: form.mu || null,
                    cnpj: form.cnpj || null,
                    company_name: form.company_name || null,
                    business_name: form.business_name || null,
                    address: {
                        postal_code: form?.address?.postal_code || null,
                        street: form?.address?.street || null,
                        number: form?.address?.number || null,
                        neighborhood: form?.address?.neighborhood || null,
                        city: form?.address?.city || null,
                        state: form?.address?.state || null,
                        additional_info: form.address?.additional_info || null,
                    },
                    financial_responsible: {
                        name: form.financial_responsible?.name || null,
                        email: form.financial_responsible?.email || null,
                        phone: form.financial_responsible.phone || null,
                        birthday: form.financial_responsible?.birthday || null,
                        cpf: form.financial_responsible?.cpf?.replace(/\D/g, '') || null,
                    },
                    value_per_user: form?.value_per_user || null,
                    min_users_number: form?.min_users_number || null,
                    close_invoice: form?.close_invoice || null,
                    payment_day: form?.payment_day || null,
                    contract_initial_day: form?.contract_initial_day || null,
                    contract_final_day: form?.contract_final_day || null,
                    contract_key: form.contract === form.contractUrl ? undefined : null,
                    responsible: {
                        name: form.responsible?.name || null,
                        email: form.re || null,
                    },
                    modules: {
                        climateCheck: form.modules?.climateCheck || false,
                    },
                    timezone: form.timezone.value,
                    type: form.type,
                    implantation_value: form?.implantation_value,
                    plan: form?.plan,
                    payment_method: form?.payment_method,
                },
            }).unwrap();

            if (file){
                const params = {
                    ContentType: file.type,
                    ContentLength: file.size,
                };

                const urlData = await getUploadContratUrl({accountId: id, params}).unwrap();
                await uploadFile(urlData.url, urlData.fields, file, {contentDisposition: false});
            }
            
            enqueueSnackbar(t('ACCOUNTS_PAGE.EDIT_SUCCESS'), {variant: 'success'});
            navigate(routes.ACCOUNTS);
        } catch (e) {
            if (e?.data?.status === 409) {
                enqueueSnackbar(t('ACCOUNTS_PAGE.EMAIL_ALREADY_REGISTERED'), {variant: 'error'});
            } else {
                enqueueSnackbar(t('ACCOUNTS_PAGE.EDIT_ERROR'), {variant: 'error'});
            }
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <TabContext value={tabIndex}>
            <div className={styles.tabs}>
                <TabList onChange={handleChange}>
                    <Tab label={t('ACCOUNTS_PAGE.TABS.ACCOUNT')} value={0} icon={showWarningIcon(accountFieldsErrors)} />
                    <Tab label={t('ACCOUNTS_PAGE.TABS.ENTERPRISE')} value={1} icon={showWarningIcon(clientFieldErrors)} />
                    <Tab label={t('ACCOUNTS_PAGE.TABS.FINANCE')} value={2} icon={showWarningIcon(financeFieldErros)} />
                    <Tab label={t('ACCOUNTS_PAGE.TABS.MODULES')} value={3} />
                    {canListBoletos && (
                        <Tab label={t('ACCOUNTS_PAGE.TABS.BOLETOS')} value={4} />
                    )}
                    {canListHistory && (
                        <Tab label={t('ACCOUNTS_PAGE.TABS.AUDIT_HISTORY')} style={{width: '160px'}} value={5} />
                    )}
                    {canListHistory && (
                        <Tab label={t('ACCOUNTS_PAGE.TABS.AUDIT_HISTORY_USER')} style={{width: '160px'}} value={6} />
                    )}
                </TabList>
            </div>
            <TabPanel value={0} children={
                <AccountEditTab
                    logoUrl={logoUrl}
                    formik={formik}
                    isLoading={isLoading} />} />
            <TabPanel value={1} children={
                <ClientEditTab
                    formik={formik}
                    isLoading={isLoading} />}  />
            <TabPanel value={2} children={
                <FinanceEditTab
                    formik={formik}
                    isLoading={isLoading}
                    file={file}
                    setFile={setFile} />} />
            <TabPanel value={3} children={
                <ModulesEditTab
                    formik={formik}
                    isLoading={isLoading} />} />
            <TabPanel value={4} children={
                <BoletosEditTab
                    formikClient={formik}
                    formikAccount={formik}
                    formikFinance={formik} />} />
            <TabPanel value={5} children={
                <AuditTab />} />
            <TabPanel value={6} children={
                <AuditUserTab />}
            />
        </TabContext>
    );
}
